.app__sidebar {
	width: 17vw;
	max-width: 210px;
	float: left;
	position: relative;
	height: 100vh;
	min-height: 470px;
}

.sidebar__logo {
	display: block;
	margin: 12px auto;
	text-align: center;

	img {
		width: 70%;
	}
}

.sidebar__logo.medinfo {
	img {
		display: none;
	}
}

.sidebar__footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.app__header__profile--sidebar {
	margin-top: 25px;
	text-align: center;
    width: 17vw;
    max-width: 210px;
    position: relative;
    top: 0;
    left: 0;
	.app__icon.app__header__icon {
		width: 100px;
		height: 100px;
		margin: 0 auto;
		float: none;
		background-position: 50% 50%;
	}
	.app__header__username {
		width: 98%;
		margin-left: 1%;
		text-align: center;
		line-height: 15px;
		float: none;
	}	

	.app__header__username div {
		font-size: 11px;
		display: inline-block;
		margin-top: 5px;
		font-family: $heading_font;
	}


}