.app__attachments {
  width: 100%;
  padding: 4px 0px 68px;
  box-sizing: border-box;
  font-family: $main-font;
  position: relative;
  margin-bottom: 6px;
  text-align: center;
  color: $brand_color_secondary;
}

.app__all-attachments {
  padding: 0px 15px;
}

.app__attachments__prompt {
  font-family: $main_font;
  font-size: 15px;
  color: $brand_color_secondary;
  margin: 30px 0 24px;
  display: block;
  font-weight: 500;
}

.app__attachments__images {
  display: flex;
  flex-wrap: wrap;
}

.app__attachments__images p,
.app__attachments__videos p {
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: $brand_color_secondary;
  margin-bottom: 5px;
}

.app__attachments__header {
  background-color: $brand_color_secondary;
  height: 29px;
  width: 100%;
  text-align: right;
}

.app__attachments__header i {
  float: left;
  color: $text-white;
  margin: 4px 0 0 8px;
  font-size: 20px;
  cursor: pointer;
}

.app__attachments__image-container {
  flex: 0 0 calc(16.66% - 18px);
  width: 148px;
  float: left;
  margin-bottom: 30px;
  margin-right: 18px;
  &:last-of-type {
    margin-right: 0;
  }
}

.app__attachments__image {
  height: 153px;
  /*background-image: url(/img/cat1.jpg);*/
  background-size: contain;
  background-color: $exlight_grey;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

p.app__attachments__video-title {
  font-weight: 700;
  color: $brand_color_main;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 6px;
}

p.app__attachments__video-dateadded {
  font-weight: 700;
  color: $brand_color_main;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 6px;
}

.app__button--attachments-delete {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.app__attachments__standalone-header {
  margin-top: 40px;
  padding-bottom: 10px;
  h4 {
    margin: 0 auto 12px;
    width: 210px;
    font-family: $heading-font;
    text-transform: uppercase;
    font-size: 20px;
    display: block;
    padding-bottom: 8px;
    border-bottom: 1px $brand_color_main solid;
  }
  p {
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 5px;
  }
}

//these styles just for demo! Remove!
.app__attachments__image-container {
  &:nth-of-type(1) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(2) .app__attachments__image {
    background-image: url(/img/cat3.jpg);
  }
  &:nth-of-type(3) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(4) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(5) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
  &:nth-of-type(6) .app__attachments__image {
    background-image: url(/img/cat4.jpg);
  }
  &:nth-of-type(7) .app__attachments__image {
    background-image: url(/img/docthumb1.png);
  }
}

.app__attachments__video-container {
  &:nth-of-type(1) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
  &:nth-of-type(2) .app__attachments__video {
    background-image: url(/img/videothumb2.png);
  }
  &:nth-of-type(3) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
  &:nth-of-type(4) .app__attachments__video {
    background-image: url(/img/videothumb1.png);
  }
}

//

.app__attachments__video,
.app__attachments__image {
  cursor: pointer;
}

.app__attachments__videos {
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100vh - 310px);
  overflow-y: auto;
}

.app__attachments__video-container {
  flex: 0 0 calc(33.33% - 18px);
  width: 314px;
  float: left;
  margin-right: 18px;
  margin-bottom: 30px;
  &:last-of-type {
    margin-right: 0;
  }
}
.app__attachments__video {
  height: 126px;
  /*background-image: url(/img/cat1.jpg);*/
  background-size: cover;
  background-position: 50% 50%;
}
.skin2 {
  .app__attachments__images p,
  .app__attachments__videos p {
    color: $table_border;
  }
  .app__attachments__prompt {
    color: $form_element_color_2;
  }
  .app__attachments__header {
    background-color: $form_element_color_2;
  }
}
.skin3 {
  .app__attachments__images p,
  .app__attachments__videos p {
    color: $table_border;
  }
  .app__attachments__prompt {
    color: $form_element_color_3;
  }
  .app__attachments__header {
    background-color: $form_element_color_3;
  }
}


.app__upload__form {
  width: 80%;
  clear: left;
}

.app__attachments__progress {
  width: 50px;
  float: left;
  margin-top: -10px;
  margin-left: 25px;
  margin-bottom: 25px;
}

.app__attachments__filename {
  border: 1px solid lightgray;
  float: left;
  width: 30%;
  padding: 5px;
  border: 1;
  border-radius: 5px;
  background: white;
}

.app__attachments__file_title {
  float: left;
  margin-left: 10px;
  padding: 7px;
  border: 1px solid lightgray;
  border-radius: 5px;
  background: white;
  width: 35%;
}

.app__attachments__brand {
  float: left;
  margin-left: 10px;
  padding: 7px;
  border: 1px solid lightgray !important;
  border-radius: 5px;
  background: white;
  width: 20%;
}

.app__header__profile .app__attachments__brand {
  width: 87%;
  margin: 15px;

  &:disabled {
    background-color: #cdcbcb;
  }
}

.upload_submit {
  float: left;
  margin-left: 10px;
  background-color: #199dde;
  border: none;
  color: #fff;
  padding: 8px 16px;
  border-radius: 5px
}

.upload__message {
  float: left;
  clear: right;
  overflow: hidden;
  padding: 10px;
  font-size: 15px;
  text-align: left;
  margin-bottom: 15px;
}

.uploaded_attachment {
  clear: both;
  float: left;
  margin: 10px 0px;
  padding: 10px;
  background-color: $brand_color_table-border;
  color: #000;
  width: 100%;
  text-align: left;
  font-size: 13px;
  border-radius: 5px;

  .uplooad_notify {
    font-weight: bold;;
  }
}

