.selectRecipientsContainer, .app__recipients  {
  height: 100%;
  }

.app__recipients__container {
  width: 100%;
  margin: 0 auto 0;
  font-family: $main-font;
  box-sizing: border-box;
  padding: 15px; 
  height: 100%;
}

.app__profile .app__recipients__container {
  padding: 10px 0 0 0;
}

.app__outlook-connect {

}

.app__recipients__connect {
  // width: 45%;
  width: auto;
  float: left;
  margin-right: 20px;
  margin-top: 26px;

  .error {
    display: block;
  }
}

.app__recipients__connect-btn {
  margin-top: 22px;
}

.app__recipients__connect .app__recipients__connect-btn {
  background-color: $brand_color_main;
  margin: 15px 0 5px;
}

.app__recipients__search {
  //background-color: $form_element_color;
  width: 48%;
 // border-radius: 20px 20px 0 0;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: left;
  float: left;
}

.app__profile .app__recipients__search {
  margin-top: 25px;
}

.app__recipients-input {
  border: 1px solid $brand_color_secondary;
  background-color: $text_white;
  width: 90%;
  height: 26px;
  font-size: 10px;
  box-sizing: border-box;
  padding-left: 29px;
  margin-bottom: 15px;
  border-radius: 3px;
}

.app__recipients-selected {
  border: 1px solid $brand_color_secondary;
  background-color: $text_white;
  width: 90%;
  margin-left: 9%;
  min-height: 59px;
  font-size: 10px;
  box-sizing: border-box;
  padding: 7px 16px 10px 16px;
  border-radius: 3px;
}

.app__recipients-selected__list span {
  margin-right: 5px;
  line-height: 14px;
}

.app__recipients-selected span,
.app__recipients__list-table span {
  color: $navigation_color;
  text-transform: none;
  cursor: default;
}

.app__recipients-selected__title {
  font-size: 11px;
  color: $form_element_color;
  font-weight: 700;
  margin-bottom: 3px;
}

.app__recipients__heading {
  color: $brand_color_secondary;
  font-weight: 700;
  font-size: 12px;
  margin: 0 0 4px;

  i {
    color: $brand_color_accent;
  }
}

.app__recipients__list {
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 65px;
}

.app__recipients__note {
  clear: both;
  padding: 10px 0px;
  color: #3e3e3e;
  font-size: 14px;
  float: left;

  &.profile__note {
    color: #fff;
  }
}

.app__recipients__commands {
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 5px solid $brand_color_main;
  overflow: hidden;
  clear: both;

  button {
    background: none;
    border: none;
    color: gray;
    align-items: center;
    font-weight: bold;
    padding: 5px 10px;
    outline: none;
    box-shadow: none;
  }

  button.active {
    color: $empty_color;
  }

  .section-1,
  .section-2,
  .section-3 {
    float: left;
    margin-right: 20px;
  }

  .section-1 {
    width: 25%;
  }

  .section-2 {
    width: 45%;
  }

  .section-2 div button:nth-child(1) {
    float: left;
    padding-left: 58px;
  }

  .section-3 {
    width: 20%;
  }

  .section-2 div span {
    float: right;
    padding-right: 31px;
  }

  .section-1 span.subtitle,
  .section-2 span.subtitle {
    background: $form_element_color;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 5px;
    font-size: 11px;
  }

  span.footnote {
    display: block;
    color: gray;
    font-size: 11px;
  }

  p {
    color: gray;
    font-size: 11px;
    padding: 0px;
    margin: 0px;
    font-style: italic;
    text-align: left;
  }
}

.skin2 .app__recipients__commands .section-1 span.subtitle,
.skin2 .app__recipients__commands .section-2 span.subtitle {
  background: $form_element_color_2;
}


.skin3 .app__recipients__commands .section-1 span.subtitle,
.skin3 .app__recipients__commands .section-2 span.subtitle {
  background: $form_element_color_3;
}

.app__profile__contacts .app__recipients__commands {
  border: none;
}

.app__recipients__list select {
  padding: 1px 17px;
}

.app__recipients__sort {
  float: left;
  text-align: left;
  color: $text-white;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  * {
    display: inline-block;
  }
}

.app__recipients__list-table tr:first-child .app__recipients__select-all span{
  color: $brand_color_accent;
}

.app__recipients__list-table {
  box-sizing: border-box;
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;


  .select_all {
    font-size: 12px;
    text-align: left;
    span {
      text-decoration: underline;
    }
  }

  .sort_by {
    text-align: right;
    font-size: 12px;
    span {
      color: $form_element_color;
      font-style: italic;
    }
  }

  td .checkbox-square {
    margin: 0 auto;
  }

  td i {
    font-size: 16px;
    color: $brand_color_secondary;
  }

  .checkbox-square:checked {
    background-color: $brand_color_accent;
    border-color: $form_element_color;
  }

  .checkbox-round,
  .checkbox-square {
    &.has-limit {
      background-color: $alert_red;
      border: 1px solid $alert_red;
      background-image: url(/img/warning.png);
      background-repeat: no-repeat;
      background-position: center;
      cursor: default;
    }

    &.has-optout {
      background-color: $med_grey2;
      border: 1px solid $med_grey2;
      cursor: default;
    }

    &.has-optout-npi {
      background-color: $med_grey2;
      border: 1px solid $med_grey2;
      cursor: default;
    }

    &.is-empty {
      background-color: $empty_color;
      border: 1px solid $empty_color;
      background-repeat: no-repeat;
      background-position: center;
      cursor: default;
    }
  }
}

.app__recipients__list-table td {
  background: $white;
  font-size: 11px;
  padding: 0 7px;
  line-height: 14px;
  
  &:first-child {
    width: 30px;

  }
}

.app__recipients__list-table tr.has-optout td {
  background-color: $med_grey2;
  border: 1px solid $med_grey2;
  cursor: default;
}

.app__recipients__list-table td.active {
  background-color: $brand_color_accent;
}

.app__profile__contacts .app__recipients__list-table td.active {
  background-color: inherit;
}

.app__recipients__list-table th {
  font-size: 10px;
  padding: 0 5px;
  font-weight: 700;
  text-align: center;
  background: $brand_color_secondary;
}

.app__recipients__list-table td.divider {
  background-color: $table_dividers;
  color: $text_white;
  font-size: 15px;
  font-weight: 700;
}

.app__recipients__list-table tr {
  background-color: $exlight_grey;
  border-bottom: 2px solid $brand_color_table-border;
  height: 34px;
  &:nth-child(2) {
    background-color: $text_white;
  }
  &:first-child {
    background-color: transparent;
    span {
      color: white;
    }
  }
  &:last-child {
    border-bottom: none;
  }

  &.has-limit {
    background-color: $alert_red;
    color: $text_white;
  }

  &.has-optout {
    background-color: $med_grey2;
    color: $text_white;
  }

  &.has-optout-npi {
    background-color: $med_grey2;
    color: $text_white;
  }
}

.app__recipients__row.app__recipients__row--selected {
  background-color: $secondary_form_color;
}

.app__recipients__row.app__recipients__row--no-email {
  background-color: $table_select;
}

.app__recipients__row.app__recipients__row--warning {
  background-color: $admin_warning_2;
}

.skin2 {
  .app__recipients-selected__title {
    font-size: 11px;
    color: $form_element_color_2;
  }

  .sort_by {
    text-align: right;
    font-size: 12px;
    span {
      color: $form_element_color_2;
      font-style: italic;
    }
  }

  .app__recipients__list-table {
    .checkbox-square:checked {
      background-color: $form_element_color_2;
      border-color: $form_element_color_2;
    }
  }

  .app__recipients__row.app__recipients__row--selected {
    background-color: $form_element_color_2;
  }
  .app__recipients__list-table td.divider {
    background-color: $secondary_form_color_2;
  }
  .app__recipients__search {
    background-color: $form_element_color_2;
  }
}

.skin3 {
  .app__recipients-selected__title {
    font-size: 11px;
    color: $form_element_color_3;
  }

  .sort_by {
    text-align: right;
    font-size: 12px;
    span {
      color: $form_element_color_3;
      font-style: italic;
    }
  }

  .app__recipients__list-table {
    .checkbox-square:checked {
      background-color: $form_element_color_3;
      border-color: $form_element_color_3;
    }
  }

  .app__recipients__row.app__recipients__row--selected {
    background-color: $form_element_color_3;
  }
  .app__recipients__list-table td.divider {
    background-color: $secondary_form_color_3;
  }
  .app__recipients__search {
    background-color: $form_element_color_3;
  }
}