.app__main-tabs {
  text-align: center;
  margin-bottom: 10px;
}

.app__main-tabs__list {
  list-style: none;
  padding-inline-start: 0;
  margin-bottom: 0;
}

.app__main-tabs__tab {
  font-family: $main_font;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  width: 148px;
  height: 30px;
  background-color: $tab-color;
  display: inline-block;
  margin-right: 7px;
  padding-top: 6px;
  border: 1px solid $tab_border;
  box-sizing: border-box;
  cursor: pointer;
  &last-of-type {
    margin-right: 0;
  }
}

.app__main-tabs__tab--selected {
  background-color: $form_element_color;
  font-weight: 700;
}

.skin2 {
  .app__main-tabs__tab--selected {
    background-color: $form_element_color_2;
  }
  .app__main-tabs__tab {
    border: 1px solid $tab_border_2;
  }
}


.skin3 {
  .app__main-tabs__tab--selected {
    background-color: $form_element_color_3;
  }
  .app__main-tabs__tab {
    border: 1px solid $tab_border_3;
  }
}

