.app__pagenavigation {
  position: fixed;
  bottom: 20px;
  // z-index: 1000;
  width: calc(100% - 17vw);
  left: 17vw;

  &.activity_nav {
    position: fixed;
    right: 0px;
    bottom: 20px;
    left: unset;
    width: unset;
  }
}

.app__pagenavigation__icon {
  i {
    font-size: 22px;
    position: relative;
    top: 2px;
    margin: 0 1px;
  }
  font-size: 16px;
  width: 116px;
  height: 40px;
  border-radius: 30px;
  background-color: $brand_color_main;
  color: $white;
  cursor: pointer;
  float: left;
  font-family: $heading_font;
  text-align: center;
  padding-top: 7px;
  margin: 0 0 0 25px;
  font-weight: 700;
  opacity: 1;
  &:nth-child(2) {
    float: right;
    margin: 0 25px 0 0;
  }
  &:nth-child(2).send {
    border-radius: 0;
    float: right;
    margin: 0 28px 0 0;
    transform: rotate(0);
    background-image: url(/img/send-button.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 84px;
  }
  &:hover {
    opacity: 0.8;
  }
  &.send-another {
    float: right;
    width: 203px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 5px;
    padding-top: 11px;
    margin-right: 20px;
  }
}
