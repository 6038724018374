@font-face {
  font-family: "Futura";
  src: url("/fonts/Futura_Bold.woff2") format("woff2");
}

@import "variables";
@import "Footer";
@import "Header";
@import "Sidebar";
@import "Steps";
@import "Tabs";
@import "PageNavigation";
@import "Modal";
@import "Recipients";
@import "Profile";
@import "EmailGrid";
@import "Attachments";
@import "ActivityTable";
@import "AdminTemplate";
@import "CustomSelect";
@import "ContactModal";
@import "_CustomRecorder";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h2, h3 {
  font-family: $heading-font;
  font-weight: 700;
  text-transform: uppercase;
}

input, textarea {
  font-family: $main_font;
}

.error, p.error {
  color: $brand_color_accent;
  padding: 15px 0px;
}

.landscapeOverlay {
  display: none;
}

#root {
  min-width: 1024px;
}

@media screen and (max-device-width: 1020px) {
  .landscapeOverlay {
    background-color: #203045;
    z-index: 100000;
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -10px;
    text-align: center;
    padding-top: 5%;
    color: white;
    font-size: 12px;
    right: 0px;
    bottom: 0px;
  }
}

///
.app__main-container {
  min-width: calc(100% - 210px);
  width: calc(100% - 17vw);
  float: left;
  height: 100vh;
}

.app__container {
  box-sizing: border-box;
  //max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  background: $brand_color_secondary-faded;
  /*border-left: 1px solid $border_color;
	border-right: 1px solid $border_color;*/
  height: calc(100vh - 60px);
  /*min-height: 100vh;*/
}

.app__content-container {
  height: 100%;
}

.app__half-column {
  width: 49.5%;
  float: left;
}

.app__simple-crumb {
  font-size: 12px;
  color: $text-white;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  top: -20px;
  left: 15px;
  i {
    color: $brand_color_accent;
    margin: 0 4px;
  }
  /*padding: 0 2px 3px 2px;
  border-bottom: 1px solid $brand_color_secondary;*/
  /*padding: 2px 3px;
  background: $brand_color_secondary;*/
}

.app__simple-crumb--dark {
  top: 0;
  color: $brand_color_main;
}

.app__button {
  height: 42px;
  width: 260px;
  box-sizing: border-box;
  background-color: $brand_color_main;
  color: $text_white;
  border: none;
  font-size: 13px;
  text-transform: uppercase;
  font-family: $main_font;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 auto 15px;
  outline: none;
}

.app__button--flat {
  height: auto;
  width: auto;
  font-size: 12px;
  padding: 10px 15px;
}

.app__button--large {
  width: 264px;
  height: 214px;
  padding-top: 162px;
  font-family: $heading-font;
  font-size: 20px;
  cursor: pointer;
}

.app__button-div--large {
  width: 264px;
  margin: 0 25px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.app__button--large + span {
  display: block;
  font-family: $heading-font;
  font-size: 26px;
  font-weight: 900;
  color: $brand_color_secondary;
  text-transform: uppercase;
  line-height: 29px;
}

.app__button--back {
  width: auto;
  position: absolute;
  right: 23px;
  background-color: $brand_color_main;
  height: 30px;
  border-radius: 4px;
  padding: 1px 10px;
}

.app__button.app__button--accent {
  background-color: $brand_color_accent;
}

.app__button--modal {
  width: 210px;
  background-color: $brand_color_secondary;
}

.app__button.app__button--video-back {
  width: 100%;
  max-width: 180px;
  background-color: $brand_color_accent;
  height: 38px;
  border-radius: 4px;
  padding: 3px 10px;
  i {
    margin-right: 2px;
    font-size: 15px;
  }
}

.app__button--sidebar {
  display: block;
  background-color: $brand_color_accent;
  max-width: 150px;
  height: 30px;
  font-size: 13px;
  float: none;
  margin: 10px auto 0;

}

.app__button--sidebar:first-of-type {
  margin-top: 15px;
}

.app__button-container--large {
  margin-top: 15vh;
  width: 100%;
  text-align: center;

}

.app__button--secondary {
  background-color: $brand_color_secondary;
}

// button instances

.app__button--multisend {
  background-image: url(/img/multisend-icon--large.png);
  background-size: 134px 130px;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-color: $brand_color_secondary;
}

.app__button--personalized {
  background-image: url(/img/customize-icon--large.png);
  background-size: 190px 190px;
  background-repeat: no-repeat;
  background-position: 50% 30%;
}

.app__button--create-video, .app__button--animated-gif {
  background-image: url(/img/icon--videocamera.png);
  background-size: 150px 170px;
  background-repeat: no-repeat;
  background-position: 50% 46%;
  background-color: $brand_color_secondary;
}

.app__button--no-video {
  background-image: url(/img/icon--skipvideo.png);
  background-size: 150px 130px;
  background-repeat: no-repeat;
  background-position: 50% 46%;
  background-color: $brand_color_secondary;
}

.app__button--select-script {
  background-image: url(/img/icon--select-script.png);
  background-size: 150px 170px;
  background-repeat: no-repeat;
  background-position: 50% 46%;
  background-color: #243e76;
}

.app__button--manual-script {
  background-image: url(/img/icon--manual-script.png);
  background-size: 150px 170px;
  background-repeat: no-repeat;
  background-position: 50% 46%;
}

.app__button--no-script {
  background-image: url(/img/icon--no-script.png);
  background-size: 150px 170px;
  background-repeat: no-repeat;
  background-position: 50% 46%;
  background-color: #243e76;
}

.app__button--library {
  background-image: url(/img/icon--library.png);
  background-size: 140px 140px;
  background-repeat: no-repeat;
  background-position: 50% 47%;
}

.app__button--photo {
  background-image: url(/img/icon--photo.png);
  background-size: 140px 140px;
  background-repeat: no-repeat;
  background-position: 50% 51%;
}

.app__half-boxes {
  width: 100%;
  padding: 0 6px;
}

.app__half-box {
  width: 49.5%;
  display: inline-block;
  vertical-align: top;
}

.app__small-input {
  font-family: $main_font;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  padding: 5px;
  width: 110px;
  margin: 0 4px;
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
}

.app__small-input__long {
  width: 170px;
}

///

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

.text-left {
  text-align: left;
}

.checkbox-round {
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 7px 7px 0 0;
}
.checkbox-round:checked {
  background-color: $form_element_color;
}

.skin2 .checkbox-round:checked {
  background-color: $form_element_color_2;
}

.checkbox-square {
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 2px;
  vertical-align: middle;
  border: 1px solid $table_border;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 1px 7px 0 0;
}

.checkbox-square:checked {
  background-color: $brand_color_accent;
}

.check-circle {
  background-image: url(/img/check-circle.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 52px;
  width: 52px;
}

.app__center {
  margin: 0 auto;
}

// MISC

.app__email-preview, .app__email-preview--centered {
  width: 500px;
  min-height: 443px;
  box-sizing: border-box;
}

.app__email-preview--centered {
  width: auto;
  min-width: 960px;
  overflow: scroll;
}

.templatePreview, .templatePreview--centered { /* email preview */ 
  transform-origin: top left;
  transform: scale(.85, .85);
  background: transparent;

  margin: 10px 0 0 10px;
  border: none;
}

.smsTemplatePreviewTitle {
  text-align: left;
  padding: 18px 0px 10px 0px;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: $brand_color_secondary;

  span {
    display: block;
    padding-bottom: 5px;
    font-size: 14px;
  }
}

.smsTemplatePreview {
  float: left;
  background-color: #fff;
  border-color: transparent;
}

.app__email-preview__subject {
  font-family: $heading_font;
  font-size: 13px;
  display: block;
  color: $brand_color_secondary;
  margin: 20px 117px;
}
.templatePreview--centered {
  transform: scale(1);
  // margin: 0 100px;
  float: left;
}

.templateEditContainer {
  text-align: center;
  position: relative;
  background-color: $brand_color_secondary-faded;
  width: 945px;

  &.sms-only {
    width: 400px;
    padding-left: 25px;
  }

  .app__custom-select {
    background: $white;
    width: 302px;
    margin-bottom: 15px;
  }
}

.app__select-topic {
  font-family: $main_font;
  margin-top: 50px;
  padding: 0 40px;
  box-sizing: border-box;
  text-align: left;
}

.app__dropdowns, .app__custom-email {
  display: none;
}

.app__email-option-tab {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  box-sizing: border-box;
  font-family: $main_font;
  font-size: 12px;
  text-transform: uppercase;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 120px;
  height: 38px;
  margin-left: 3px;
  cursor: pointer;
  background-size: cover;
  background: $brand_color_main;
  color: #fff;
  text-align: center;
  padding-top: 13px;
  &.active {
    background: $brand_color_secondary;
  } 

  &:nth-child(1) {
    right: 145px;
  }
}

.app__custom-subject, .app__custom-text {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}

.app__dropdowns.active {
  display: block;
  position: absolute;
  top: 58px;
  right: 20px;
  background: $brand_color_secondary;
  width: 350px;
  padding: 25px;
}

.app__custom-email.active {
  display: block;
  position: absolute;
  top: 58px;
  right: 20px;
  background: #243e76;
  width: 350px;
  padding: 25px;
}

.select.app__select-topic__top-select {
}

.app__select-topic label {
  font-weight: bold;
  color: $white;
  font-size: 12px;
  padding: 0px 0px 4px 0px;
  display: block;
}

.app__video__preview-timer {
  width: 52px;
  padding: 3px;
  border-radius: 3px;
  color: $text_white;
  background-color: rgba(80, 80, 80, .7);
  position: absolute;
  left: calc(50% + 140px);
  top: 8px;
}

/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
  font-size: 12px;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

.app__select-topic__button {
  width: 100%;
  height: 40px;
  color: $text_white;
  background-color: $brand_color_accent;
  font-weight: 400;
  border: none;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background-color: $navigation_hover;
  }
}

.app__back-to-edit__button {
  width: 180px;
  height: 40px;
  color: $text_white;
  background-color: $navigation_color;  
  font-weight: 400;
  border: none;
  font-size: 14px;  
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    background-color: $navigation_hover;
  }
}

.app__close-schedule__button {
  width: 40px;
  height: 40px;
  color: $text_white;
  background-color: $navigation_color;  
  font-weight: 400;
  border: none;
  font-size: 28px;    
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 20px;
  &:hover {
    background-color: $navigation_hover;
  }
}

.app_close-profile__button {
  width: 40px;
  height: 40px;
  color: #ffffff;
  background-color: #2699fb;
  font-weight: 400;
  border: none;
  font-size: 28px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}

.app__send-email__button {
  width: 180px;
  height: 36px;
  color: $text_white;
  background-color: $brand_color_accent;
  font-weight: 400;
  border: none;
  font-size: 14px;  
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  &:hover {
    background-color: $navigation_hover;
  }
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 210px;
  height: 30px;
  line-height: 3;
  background: $white;
  border: 1px solid $form_element_color;
  margin-bottom: 25px;
}
select {
  flex: 1;
  padding: 0 0.5em;
  color: #333;
  cursor: pointer;
  border-radius: 0;
}
/* Arrow */
.select::after {
  box-sizing: border-box;
  color: $text_white;
  /*font-family: "Font Awesome 5 Free"; 
  font-weight: 900;
  font-size: 20px;*/
  content: url(/img/down-arrow.svg);
  position: absolute;
  top: -9px;
  right: 0;
  padding: 0px 9px;
  height: 39px;
  background: $form_element_color;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* Transition */
.select:hover::after {
  color: #eee;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Helper Classes */

.display-none {
display: none;
}

/* Alternate Branding */

.skin2 {
  .select::after {
    background: $form_element_color_2;
  }
  .select {
    border: 1px solid $form_element_color_2;
  }
  .app__select-topic label {
    color: $form_element_color_2;
  }
  .check-circle {
    background-image: url(/img/check-circle_grey.svg);
  }

  .checkbox-round:checked {
    background-color: $form_element_color_2;
  }
}


.skin3 {
  .select::after {
    background: $form_element_color_3;
  }
  .select {
    border: 1px solid $form_element_color_3;
  }
  .app__select-topic label {
    color: $form_element_color_3;
  }
  .check-circle {
    background-image: url(/img/check-circle_grey.svg);
  }

  .checkbox-round:checked {
    background-color: $form_element_color_3;
  }
}